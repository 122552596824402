import React,{useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import Home from './components/screens/Home';
import Invite from './components/screens/Invite';
import Recharge from './components/screens/Recharge';
import Account from './components/screens/Account';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import ForgotPassword from './components/screens/ForgotPassword';
import ChangePassword from './components/screens/ChangePassword';
import GameRecords from './components/screens/GameRecords';
import Transactions from './components/screens/Transactions';
import AllBankCards from './components/screens/AllBankCards';
import AddBankCard from './components/screens/AddBankCard';
import AddCryptoAddress from './components/screens/AddCryptoAddress';
import MyOrders from './components/screens/MyOrders';
import Withdraw from './components/screens/Withdraw';
import WithdrawRecords from './components/screens/WithdrawRecords';
import RechargeRecords from './components/screens/RechargeRecords';
import InviteLink from './components/screens/InviteLink';
import Rewards from './components/screens/Rewards';
import AgentReports from './components/screens/AgentReports';
import Wingo1Min from './components/Games/Wingo/Game';
import Wingo3Min from './components/Games/Wingo/Game2';
import Wingo5Min from './components/Games/Wingo/Game3';
import Wingo10Min from './components/Games/Wingo/Game4';

import Spare from './components/Games/Spare';
import Parity from './components/Games/Parity';
import Dice from './components/Games/Dice';
import AndarBahar from './components/Games/AndarBahar/Game';
import Aviator from './components/Games/Aviator/Game';

import UnderMaintenance from './components/screens/UnderMaintenance';
import Wheelocity from './components/Games/Wheelocity';
import GoldMiner from './components/Games/GoldMiner';
import DragonTiger from './components/Games/DragonTiger';

import DeviceManager from './components/screens/DeviceManager';
import UpdateAccount from './components/screens/UpdateAccount';
import ChangeAvatar from './components/screens/ChangeAvatar';
import ChangeName from './components/screens/ChangeName';
import AllMembers from './components/screens/AllMembers';
import Winners from './components/screens/Winners';
import Privilege from './components/screens/Privilege';
import RedeemGiftCard from './components/screens/RedeemGiftcard';
import Activity from './components/screens/Activity';
import DailyCheckIn from './components/screens/DailyCheckIn';
import K3Lottery1Min from './components/Games/k3Lottery/Game';
import K3Lottery3Min from './components/Games/k3Lottery/Game2';
import K3Lottery5Min from './components/Games/k3Lottery/Game3';
import K3Lottery10Min from './components/Games/k3Lottery/Game4';
import SplashScreen from './components/other-components/SplashScreen/SplashScreen';

function App() {

  return <>
  <Router>

    <Routes>
      <Route path='/home' element={<Home/>} />
      <Route path='/invite' element={<Invite/>} />
      <Route path='/winners' element={<Winners/>} />
      <Route path='/recharge' element={<Recharge/>} />
      <Route path='/account' element={<Account/>} />
      <Route path='/activity' element={<Activity/>} />
      <Route path='/gamerecords' element={<GameRecords/>} />
      <Route path='/myorders' element={<MyOrders/>} />
      <Route path='/transactions' element={<Transactions/>} />
      <Route path='/LG' element={<Login/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/RG' element={<Register/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/changepassword' element={<ChangePassword/>} />
      <Route path='/forgotpassword' element={<ForgotPassword/>} />
      <Route path='/withdraw' element={<Withdraw/>} />
      <Route path='/bankcards' element={<AllBankCards/>} />
      <Route path='/addbankcard' element={<AddBankCard/>} />
      <Route path='/addcryptoaddress' element={<AddCryptoAddress/>} />
      <Route path='/withdrawrecords' element={<WithdrawRecords/>} />
      <Route path='/rechargerecords' element={<RechargeRecords/>} />
      <Route path='/invitelink' element={<InviteLink/>} />
      <Route path='/rewards' element={<Rewards/>} />
      <Route path='/agentreports' element={<AgentReports/>} />
      <Route path='/devicemanager' element={<DeviceManager/>} />
      <Route path='/updateaccount' element={<UpdateAccount/>} />
      <Route path='/changeavatar' element={<ChangeAvatar/>} />
      <Route path='/changename' element={<ChangeName/>} />
      <Route path='/allmembers' element={<AllMembers/>} />
      <Route path='/privilege' element={<Privilege/>} />
      <Route path='/redeemgiftcard' element={<RedeemGiftCard/>} />
      <Route path='/dailycheckin' element={<DailyCheckIn/>} />

      <Route path='/Parity' element={<Parity/>} />
      <Route path='/Spare' element={<Spare/>} />
      <Route path='/wingo1min' element={<Wingo1Min/>} />
      <Route path='/wingo3min' element={<Wingo3Min/>} />
      <Route path='/wingo5min' element={<Wingo5Min/>} />
      <Route path='/wingo10min' element={<Wingo10Min/>} />

      <Route path='/k3lottery1min' element={<K3Lottery1Min/>} />
      <Route path='/k3lottery3min' element={<K3Lottery3Min/>} />
      <Route path='/k3lottery5min' element={<K3Lottery5Min/>} />
      <Route path='/k3lottery10min' element={<K3Lottery10Min/>} />

      <Route path='/Dice' element={<Dice/>} />
      <Route path='/AndarBahar' element={<AndarBahar/>} />
      <Route path='/Wheelocity' element={<Wheelocity/>} />
      <Route path='/GoldMiner' element={<GoldMiner/>} />
      <Route path='/Aviator' element={<Aviator/>} />
      <Route path='/DragonTiger' element={<DragonTiger/>} />

      <Route path='/um' element={<UnderMaintenance/>} />

      {/* default page */}
      <Route path='/' element={<SplashScreen/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
