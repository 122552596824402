import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setStorage, getStorage } from "../../modals/Storage";
import { setSessionStorage, getSessionStorage } from "../../modals/Session";
import styles from "../../css/Screens.module.css";
import general from "../../css/General.module.css";

function SplashScreen() {
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState(3);

  const [pageConst, setConstants] = useState({
    pageTitle: "Splash Screen",
  });

  const redirectToApp = () => {
    if (getStorage("uid")) {
      navigate("/home", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    if (getSessionStorage("isPageLoaded") >= 1) {
        redirectToApp();
    } else {
      const timer = setInterval(() => {
        if (countDown > 1) {
          setCountDown(countDown - 1);
        } else {
          clearInterval(timer);
          setSessionStorage("isPageLoaded", 1)
          redirectToApp();
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown]);

  return (
    <div
      className={[
        general.viewCenter,
        general.appBackground
      ].join(" ")}
    >
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.viewCenter,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <div className={[general.width100, general.viewCenter, general.flexDirColumn, styles.splashScreenView].join(" ")}>
            <img className={[styles.splashScreenImage]} src={require("../../media/icons/splash_screen_icon.png")} alt="icon" />
            <p>Withdraw fast, safe and stable</p>
            <img className={[styles.splashScreenLogo]} src={require("../../media/icons/app_logo.png")} alt="icon" />
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
